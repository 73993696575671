<template>
 <div class="headerarea">
   <div class="container">
     <div class="d-flex justify-content-between align-items-center">
      <a href="#" class="btn-link" @click="() => gotoSearchPage()">
          <span>
            <i class="fa-solid fa-chevron-right"></i>
          </span>
          {{$t('sabre.booking.return-site')}}
        </a>
        <div class="logo">
          <img src="/assets/sabre-img/logo.png" alt="#">
        </div>
     </div>
   </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'header-area',
  computed: {
    ...mapGetters({
      searchQuery: 'GET_SABRE_BOOKING_FLIGHT_SEARCH_QUERY',
    }),
  },
  methods: {
    gotoSearchPage() {
      this.$store.commit('SET_SABRE_FLIGHT_FILTERED_ITEMS', []);
      this.$router.push({ path: `/sabre/fo-search-result/?${this.searchQuery}` });
    },
  },
};
</script>
